import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCu52PvJpQA6IqqC2e98JuRA0qTBEZWgDs",
  authDomain: "quickinsure-decision.firebaseapp.com",
  projectId: "quickinsure-decision",
  storageBucket: "quickinsure-decision.appspot.com",
  messagingSenderId: "811033708341",
  appId: "1:811033708341:web:141508d7928543234a6e7c",
  measurementId: "G-HC51T450JZ",
};

firebase.initializeApp(firebaseConfig);
export default firebase;