import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { InputLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';


export class FormUserDetails extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const { values, handleChange } = this.props;
    return (
      <MuiThemeProvider>
        <>
          <Dialog open fullWidth maxWidth="sm">
            <AppBar style={{ backgroundColor: '#29cb55' }} >
            <Toolbar>
              <Typography variant="h6">
                Health Basic Details
              </Typography>
            </Toolbar>
          </AppBar>
            <FormControl variant="outlined">
            <InputLabel id="pt_label">Policy Type</InputLabel>
              <Select
                labelId="pt_label"
                id="demo-simple-select-outlined"
                value={values.tp}
                onChange={handleChange("tp")}
                label="Policy Type"
              >
                <MenuItem value={'ind'}>Individual</MenuItem>
                <MenuItem value={'min'}>Multi Individual</MenuItem>
                <MenuItem value={'fam'}>Family Floater</MenuItem>
              </Select>
            </FormControl>
            <br />
            <FormControl variant="outlined">
            <InputLabel id="si_label">Sum Insured</InputLabel>
              <Select
                labelId="si_label"
                id="demo-simple-select-outlined"
                value={values.isLessOrMore}
                onChange={handleChange("isLessOrMore")}
                label="More Or Less"
              >
                <MenuItem value={'less'}>Less Than 5</MenuItem>
                <MenuItem value={'more'}>More Than 5</MenuItem>
              </Select>
            </FormControl>
            <br />

            <FormControl variant="outlined">
            <InputLabel id="si_label">Requirement</InputLabel>
              <Select
                labelId="si_label"
                id="demo-simple-select-outlined-label"
                value={values.isSpecific}
                onChange={handleChange("isSpecific")}
                label="More Or Less"
              >
                <MenuItem value={'specific'}>Specific</MenuItem>
                <MenuItem value={'notspecific'}>Not Specific</MenuItem>
              </Select>
            </FormControl>
            <br />

            {values.isSpecific == 'specific' && 
              <>
              <FormControl variant="outlined">
              <InputLabel id="req_label">Specification</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={values.yChoice}
                onChange={handleChange("yChoice")}
                label="More Or Less"
              >
                <MenuItem value={'maternity'}>Maternity</MenuItem>
                <MenuItem value={'dailycash'}>Daily Cash</MenuItem>
                <MenuItem value={'opd'}>OPD</MenuItem>
              </Select>
            </FormControl>
            <br />
              </>
            }
            

            <Button style={{ backgroundColor: '#29cb55' }} variant="contained" onClick={this.continue}>
              Continue
            </Button>
          </Dialog>
        </>
      </MuiThemeProvider>
    );
  }
}

export default FormUserDetails;
