import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { InputLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';


export class Login extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
    window.sessionStorage.setItem("isLoggedIn", true);
  };

  render() {
    const { values, handleChange } = this.props;
    return (
      <MuiThemeProvider>
        <>
          <Dialog open fullWidth maxWidth="sm">
            <AppBar style={{ backgroundColor: "#29cb55" }}>
              <Toolbar>
                <Typography variant="h6">Health Basic Details</Typography>
              </Toolbar>
            </AppBar>
            <FormControl variant="outlined">
              <TextField
                id="outlined-basic"
                label="Email"
                value="health@quickinsure.co.in"
                variant="outlined"
                disabled
              />
              <br />
              <TextField
                type="password"
                id="outlined-basic"
                label="Password"
                name="password"
                onChange={handleChange("password")}
                variant="outlined"
              />
            </FormControl>

            <br />

            {values.password == "@fruit!" && (
              <Button
                style={{ backgroundColor: "#29cb55" }}
                variant="contained"
                onClick={this.continue}
              >
                Continue
              </Button>
            )}
          </Dialog>
        </>
      </MuiThemeProvider>
    );
  }
}

export default Login;
