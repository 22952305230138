import React, { Component } from 'react';
import FormUserDetails from './FormUserDetails';
import FormPersonalDetails from './FormPersonalDetails';
import Confirm from './Confirm';
import Login from './Login';
import Success from './Success';

export class UserForm extends Component {
  state = {
    step: window.sessionStorage.getItem("isLoggedIn") ? 2 : 1,
    ch: 0,
    tp: 'ind',
    sumInsured: '100000',
    isLessOrMore: 'less',
    isSpecific: 'notspecific',
    yChoice: 'maternity',
    password: ''
  };

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  // Handle fields change
  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };


  render() {
    
    const { ch } = this.state;
    const { step } =  this.state;
    const { sumInsured, tp, isLessOrMore, isSpecific, yChoice, password } = this.state;
    const values = { sumInsured, tp, isLessOrMore, isSpecific, yChoice, password };
    

    switch (step) {
      case 1:
        return (
          <Login
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 2:
        return (
          <FormUserDetails
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 3:
        return <Success values={values} />;
      default:
        (console.log('This is a multi-step form built with React.'))
    }
  }
}

export default UserForm;
