import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PlanList from './List';

export class Success extends Component {
  continue = e => {
    e.preventDefault();
    // PROCESS FORM //
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  refreshPage = () => {
    window.location.reload(false);
  } 

  render() {
    const {
      values: { sumInsured, tp, isLessOrMore, isSpecific, yChoice }
    } = this.props;
    console.log(isSpecific, isLessOrMore)
    
    return (
      <MuiThemeProvider>
        <>
          <Dialog open fullWidth maxWidth="sm">
            <AppBar style={{ backgroundColor: "#29cb55" }}>
              <Toolbar>
                <Typography variant="h6">Available Products</Typography>
              </Toolbar>
            </AppBar>

            {/* individual */}
            {tp === "ind" && isSpecific === "notspecific" && isLessOrMore === "less" && <><PlanList choice="0" /></>}
              {tp === "ind" && isSpecific === "specific" && isLessOrMore === "less" && yChoice === "maternity" && <><PlanList choice="1"  /></>}
              {tp === "ind" && isSpecific === "specific" && isLessOrMore === "less" && yChoice === "dailycash" && <><PlanList choice="2"  /></>}
              {tp === "ind" && isSpecific === "specific" && isLessOrMore === "less" && yChoice === "opd" && <><PlanList choice="3" /></>}

              {tp === "ind" && isSpecific === "notspecific" && isLessOrMore === "more" && <><PlanList choice="4" /></>}
              {tp === "ind" && isSpecific === "specific" && isLessOrMore === "more" && yChoice === "maternity" && <><PlanList choice="5" /></>}
              {tp === "ind" && isSpecific === "specific" && isLessOrMore === "more" && yChoice === "dailycash" && <><PlanList choice="6" /></>}
              {tp === "ind" && isSpecific === "specific" && isLessOrMore === "more" && yChoice === "opd" && <><PlanList choice="7" /></>}

              {/* multi individual */}
              {tp === "min" && isSpecific === "notspecific" && isLessOrMore === "less" && <><PlanList choice="8" /></>}
              {tp === "min" && isSpecific === "specific" && isLessOrMore === "less" && yChoice === "maternity" && <><PlanList choice="9" /></>}
              {tp === "min" && isSpecific === "specific" && isLessOrMore === "less" && yChoice === "dailycash" && <><PlanList choice="10" /></>}
              {tp === "min" && isSpecific === "specific" && isLessOrMore === "less" && yChoice === "opd" && <><PlanList choice="11" /></>}

              {tp === "min" && isSpecific === "notspecific" && isLessOrMore === "more" && <><PlanList choice="12" /></>}
              {tp === "min" && isSpecific === "specific" && isLessOrMore === "more" && yChoice === "maternity" && <><PlanList choice="13" /></>}
              {tp === "min" && isSpecific === "specific" && isLessOrMore === "more" && yChoice === "dailycash" && <><PlanList choice="14" /></>}
              {tp === "min" && isSpecific === "specific" && isLessOrMore === "more" && yChoice === "opd" && <><PlanList choice="15" /></>}

              {/* family  */}
              {tp === "fam" && isSpecific === "notspecific" && isLessOrMore === "less" && <><PlanList choice="16" /></>}
              {tp === "fam" && isSpecific === "specific" && isLessOrMore === "less" && yChoice === "maternity" && <><PlanList choice="17" /></>}
              {tp === "fam" && isSpecific === "specific" && isLessOrMore === "less" && yChoice === "dailycash" && <><PlanList choice="18" /></>}
              {tp === "fam" && isSpecific === "specific" && isLessOrMore === "less" && yChoice === "opd" && <><PlanList choice="19" /></>}

              {tp === "fam" && isSpecific === "notspecific" && isLessOrMore === "more" && <><PlanList choice="20" /></>}
              {tp === "fam" && isSpecific === "specific" && isLessOrMore === "more" && yChoice === "maternity" && <><PlanList choice="21" /></>}
              {tp === "fam" && isSpecific === "specific" && isLessOrMore === "more" && yChoice === "dailycash" && <><PlanList choice="22" /></>}
              {tp === "fam" && isSpecific === "specific" && isLessOrMore === "more" && yChoice === "opd" && <><PlanList choice="23" /></>}

            {/* <List>
              
            
              {isSpecific === "notspecific" && isLessOrMore === "less" && (
                <>
                  <ListItem>
                    <ListItemText primary="Tata Medicare, Protect" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Star Youngstar" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Star Mediclassic" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Cigna Prohealth Protect" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Optima Restore" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="HDFC SilverSmart" />
                  </ListItem>
                </>
              )}

              {isSpecific === "specific" &&
                isLessOrMore === "less" &&
                yChoice === "maternity" && (
                  <>
                    <ListItem>
                      <ListItemText primary="Star Youngstar Gold" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Bajaj Health Guard Gold" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Cigna Prohealth Plus" />
                    </ListItem>
                  </>
                )}
              {isSpecific === "specific" &&
                isLessOrMore === "less" &&
                yChoice === "dailycash" && (
                  <>
                    <ListItem>
                      <ListItemText primary="Star Youngstar Gold" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Tata Medicare" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="ICICI Health Shield " />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Cigna Prohealth Plus, Protect" />
                    </ListItem>
                  </>
                )}
              {isSpecific === "specific" &&
                isLessOrMore === "less" &&
                yChoice === "opd" && (
                  <>
                    <ListItem>
                      <ListItemText primary="Star Youngstar Gold" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Tata Medicare" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="ICICI Health Shield " />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Cigna Prohealth Plus, Protect" />
                    </ListItem>
                  </>
                )}

              {isSpecific === "notspecific" && isLessOrMore === "more" && (
                <>
                  <ListItem>
                    <ListItemText primary="Star Comprehensive" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Tata Medicare" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Health Elite " />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Optima Restore" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Prohealth Accumulate" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Care With NCB + UAR" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="GoldSmart, PlatinumSmart" />
                  </ListItem>
                </>
              )}

              {isSpecific === "specific" &&
                isLessOrMore === "more" &&
                yChoice === "maternity" && (
                  <>
                    <ListItem>
                      <ListItemText primary="Star Comprehensive" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Tata Medicare Premier" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="ICICI Health Elite " />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Cigna Prohealth Plus" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Star Young Gold" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Bajaj Health Gold Platinum" />
                    </ListItem>
                  </>
                )}
              {isSpecific === "specific" &&
                isLessOrMore === "more" &&
                yChoice === "dailycash" && (
                  <>
                    <ListItem>
                      <ListItemText primary="Star Youngstar Gold" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Tata Medicare" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="ICICI Health Shield " />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Cigna Prohealth Plus, Protect" />
                    </ListItem>
                  </>
                )}
              {isSpecific === "specific" &&
                isLessOrMore === "more" &&
                yChoice === "opd" && (
                  <>
                    <ListItem>
                      <ListItemText primary="Star Comprehensive" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Tata Medicare Premier" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Health Shield " />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Prohealth Plus, Protect" />
                    </ListItem>
                  </>
                )}
            </List> */}
            <br />

            <Button
              style={{ backgroundColor: "#29cb55" }}
              variant="contained"
              onClick={this.refreshPage}
            >
              Back
            </Button>
          </Dialog>
        </>
      </MuiThemeProvider>
    );
  }
}

export default Success;
