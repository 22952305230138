import React, { useState} from "react";
import { List, ListItem, ListItemText } from '@material-ui/core/';


const plans = [
    {
        id: "0",
        name: "Individual, Not Spcific, Less than 5 Lacks",
        options: ["Tata Medicare, Protect", "Star Youngstar", "Star Mediclassic", "Optima Restore", "Cigna Prohealth Protect", "HDFC Silver Smart", "Care Health", "Reliance health Gain"]
    },
    {
        id: "1",
        name: "Individual, Spcific, Less than 5 Lacks, Maternity",
        options: ["Start Youngstar Gold", "Bajaj Health Guard Gold", "Cigna Prohealth Plus"]
    },
    {
        id: "2",
        name: "Individual, Spcific, Less than 5 Lacks, Daily Cash",
        options: ["Start Youngstar Gold", "Tata Medicare", "ICICI Health Shield", "ICICI Care Health", "Cigna Prohealth Plus, Protect"]
    },
    {
        id: "3",
        name: "Individual, Spcific, Less than 5 Lacks, OPD",
        options: ["Star Youngstar Gold", "Tata Medicare", "ICICI Health Shield", "Cigna Prohealth Plus, Protect"]
    },
    {
        id: "4",
        name: "Individual, Not Spcific, More than 5 Lacks",
        options: ["Star Comprehensive", "Tata Medicare", "Health Elite", "Optima Restore", "Care with NCB + UAR", "Prohealth Accumulate", "Goldsmart Plantinumsmart", "Reliance Health Gain" ]
    },
    {
        id: "5",
        name: "Individual, Spcific, More than 5 Lacks, Maternity",
        options: ["Star Comprehensive", "Tata Medicare Premier", "ICICI Health Elite", "Cigna Prohealth Plus", "Start Young Gold", "Bajaj Health Gold, Platinum"]
    },
    {
        id: "6",
        name: "Individual, Spcific, More than 5 Lacks, Daily Cash",
        options: ["Tata Medicare", "Star Youngstar Gold", "ICICI Health Shield", "Cigna Prohealth Plus, Protect", "Health Care"]
    },
    {
        id: "7",
        name: "Individual, Spcific, Less than 5 Lacks, OPD",
        options: ["Tata Medicare Premier", "Star Comprehensive", "Health Elite", "Prohealth Protect, Plus"]
    },
    {
        id: "8",
        name: "Multi Individual, Not Spcific, Less than 5 Lacks",
        options: ["Tata Medicare Protect", "ICICI Health Shield", "Star Mediclassic", "Cigna Prohealth Protect, Plus", "Optima Restore", "HDFC Silversmart", "Basic Care", "Bajaj Health Gold"]
    },
    {
        id: "9",
        name: "Multi Individual, Spcific, Less than 5 Lacks, Maternity",
        options: ["Cigna Prohealth Plus", "Bajaj Health Guard Gold"]
    },
    {
        id: "10",
        name: "Multi Individual, Spcific, Less than 5 Lacks, Daily Cash",
        options: ["Tata Medicare", "ICICI Health Shield", "Cigna Prohealth Plus, Protect", "Bajaj Health Guard Gold"]
    },
    {
        id: "11",
        name: "Multi Individual, Spcific, Less than 5 Lacks, OPD",
        options: ["Cigna Prohealth Protect, Plus"]
    },
    {
        id: "12",
        name: "Multi Individual, Not Spcific, More than 5 Lacks",
        options: ["Tata Medicare, Protect", "ICICI Health Elite", "Optima Restore", "Prohealth Accumulate", "Care with NCB plus UAR", "Goldsmart, Plantinumsmart", "Bajaj Gold/Platinum"]
    },
    {
        id: "13",
        name: "Multi Individual, Spcific, More than 5 Lacks, Maternity",
        options: ["Tata Medicare, Premier", "ICICI Health Elite", "Cigna Prohealth Plus", "Bajaj Health Guard Gold/Platinum"]
    },
    {
        id: "14",
        name: "Multi Individual, Spcific, More than 5 Lacks, Daily Cash",
        options: ["Optima Restore", "Tata Medicare Premier", "ICICI Health Shield, Elite", "Care NCB + UAR", "Bajaj Health Guard Gold"]
    },
    {
        id: "15",
        name: "Multi Individual, Spcific, More than 5 Lacks, OPD",
        options: ["Tata Medicare Premier", "Health Elite"]
    },
    {
        id: "16",
        name: "Family Floater, Not Spcific, Less than 5 Lacks",
        options: ["Tata Medicare, Protect", "Star Family Health Optima", "Basic Care, Care + NCB Super", "Cigna Prohealth Protect", "Optima Restore", "HDFC Silversmart", "ICICI Health Shield", "Reliance Health Gain"]
    },
    {
        id: "17",
        name: "Family Floater, Spcific, Less than 5 Lacks, Maternity",
        options: ["Star Youngstar Gold", "Bajaj Health Guard Gold", "Cigna Prohealth Plus"]
    },
    {
        id: "18",
        name: "Family Floater, Spcific, Less than 5 Lacks, Daily Cash",
        options: ["Star Youngstar Gold", "ICICI Health Shield", "Cigna Prohealth Plus, Protect", "Bajaj Health Guard Gold"]
    },
    {
        id: "19",
        name: "Family Floater, Spcific, Less than 5 Lacks, OPD",
        options: ["Star Youngstar Gold", "Cigna Prohealth Plus, Protect"]
    },
    {
        id: "20",
        name: "Family Floater, Not Spcific, More than 5 Lacks",
        options: ["Star Comprehensive, Family Health Optima", "Tata Medicare, Premier", "ICICI Health Shield, Elite", "Optima Restore", "Prohealth Accumulate", "Care with NCB + UAR", "Goldsmart, Plantinumsmart", "Reliance Health Gain"]
    },
    {
        id: "21",
        name: "Family Floater, Spcific, More than 5 Lacks, Maternity",
        options: ["Star Comprehensive", "Tata Medicare Premier", "ICICI Health Elite", "Cigna Prohealth Plus", "Star Young Gold", "Bajaj Health Gold, Platinum"]
    },
    {
        id: "22",
        name: "Family Floater, Spcific, More than 5 Lacks, Daily Cash",
        options: ["Star Youngstar Gold", "Tata Medicare, Premier", "ICICI Health Shield", "Cigna Prohealth Plus, Protect", "Optima Restore", "Care NCB + UAR", "Bajaj Health Guard Gold"]
    },
    {
        id: "23",
        name: "Family Floater, Spcific, Less than 5 Lacks, OPD",
        options: ["Star Comprehensive", "Tata Medicare Premier", "Health Elite", "Prohealth Protect, Plus", "Star Youngster Gold"]
    }
]


function PlanList(props) {
  
  const plan = plans[props.choice];
  console.log(plan);

  return (
    <div>
      <List>
        {plan.options.map((option) => (
          <ListItem key={option}>
            <ListItemText primary={option} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default PlanList;
